import React from 'react';
import './AdVerticalLeft.css';

const AdVerticalLeft = () => {
    return (
        <div id='adVerticalLeft'>

        </div>
    );
};

export default AdVerticalLeft;