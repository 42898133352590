import React from 'react';
import './AdVerticalRight2.css';

const AdVerticalRight2 = () => {
    return (
        <div id='adVerticalRight2'>
            
        </div>
    );
};

export default AdVerticalRight2;