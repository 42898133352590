import React from 'react';
import './AdVerticalRight.css';

const AdVerticalRight = () => {
    return (
        <div id='adVerticalRight'>
            
        </div>
    );
};

export default AdVerticalRight;