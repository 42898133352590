import React from 'react';
import './AdVerticalLeft2.css';

const AdVerticalLeft2 = () => {
    return (
        <div id='adVerticalLeft2'>
            
        </div>
    );
};

export default AdVerticalLeft2;